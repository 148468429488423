.section4-container {
  width: 100%;
  max-width: 100%;
  background-color: $color-dark;
  color: $color-text-white;
  font-weight: 500;
  line-height: 1.5em;
  padding: 40px 80px;

  @media (max-width: $breakpoint-tablet) {
    padding: 40px 30px;
  }

  h1 {
    margin-bottom: 1.5em;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      margin: 0;
      padding-left: 3.5rem;
      position: relative;
      width: 30%;
      margin-bottom: 40px;

      @media (max-width: $breakpoint-tablet) {
        width: 100%;
      }

      h4 {
        font-size: 18px;
      }
      p {
        font-size: 16px;
        margin: 0;
        display: block;
        text-align: justify;
        text-justify: inter-word;
        text-align-last: left;

        &::before {
          content: "";
          display: block;
          position: absolute;
          left: 1rem;
          width: 0.2rem;
          height: 1.3rem;
          transform: skewX(20deg);
          border: solid $color-red;
          border-width: 0 0.6rem;
        }
      }
    }
  }

  .section4-name {
    color: rgb(160, 159, 159);
    margin: 0;
    margin-top: 0.5em;
    display: flex;
    flex-direction: column;
  }
  .section4-company {
    display: block;
    margin-top: 0.2em;
    align-self: flex-start;
    background: $color-red;
    color: $color-dark;
    padding: 0 5px;
  }
}
