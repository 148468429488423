.section1-container {
  width: 100%;
  max-width: 100%;
  color: $color-text-black;
  padding: 40px 80px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5em;
  text-align: justify;

  @media (max-width: $breakpoint-tablet) {
    padding: 40px;
  }

  h1 {
    line-height: 1.2em;
  }

  ul {
    margin: 0;
    padding-left: 17px;
    li {
      margin-bottom: 0.8em;
    }
  }

  .primary-action {
    display: block;
    background: #ff0f54;
    color: #212b34;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    width: max-content;
    font-size: 28px;
    font-weight: 600;
    margin-top: 1em;
    margin-bottom: 1em;

    @media (max-width: $breakpoint-tablet) {
      width: 100% !important;
      font-size: 24px;
      padding: 5px;
    }
  }

  img.section1-img {
    float: right;
    margin-top: 90px;
    shape-outside: polygon(
      95% 90px,
      calc(95% - 280px) 90px,
      calc(95% - 460px) calc(100% - 350px),
      calc(95% - 460px) calc(100% - 240px),
      calc(95% - 380px) calc(100% - 200px),
      calc(95% - 280px) calc(100% - 100px),
      calc(95% - 500px) calc(100% - 90px),
      calc(95% - 500px) 535px,
      95% 535px
    );

    @media (max-width: $breakpoint-tablet) {
      margin-top: 20px;
      margin-left: 15px;
      width: 300px;
      height: initial;
      shape-outside: none;
    }

    @media (max-width: $breakpoint-mobile) {
      display: block;
      float: none;
      width: 100% !important;
      height: initial;
    }
  }
}
