$width: 20vw;
$radius: 100px;

.footer {
  width: 100vw;
  max-width: 100%;
  color: #2c2b3c;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2em;
  padding: 40px 80px;
  padding-bottom: 20px;
  display: flex;
  overflow: hidden;

  @media (max-width: $breakpoint-mobile) {
    & {
      flex-direction: column;
      padding: 40px;
      font-size: 16px;
    }
  }

  .footer-logo {
    width: 120px;
    opacity: 0.2;
    margin-right: 80px;
    align-self: flex-start;

    @media (max-width: $breakpoint-mobile) {
      & {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
      }
    }
  }

  .footer-text {
    h1 {
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
      margin-top: 0;
    }

    p {
      font-size: 16px;
    }

    a {
      color: inherit;
      font-weight: 500;
    }
  }

  .footer-link {
    display: block;
    margin-bottom: 24px;
  }

  .footer-link.primary {
    display: block;
    margin-top: 0.2em;
    background: #ff0f54;
    color: #212b34;
    padding: 8px 20px;
    text-align: center;
    text-decoration: none;
    width: max-content;
    font-size: 28px;
    font-weight: 600;
  }

  .footer-copyright {
    margin-top: 60px;
  }
}
