.screen {
  .loading-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    background-color: #fff;
    background-size: cover;
    animation: turn-white $loading-dur ease-in forwards;
  }

  .logo {
    position: absolute;
    width: 20vw;
    top: calc(50vh - (#{$logo-ratio} * 10vw));
    left: 40vw;
    animation: turn-black $loading-dur ease-in forwards,
      go-to-corner $moving-dur $loading-dur forwards;

    @media (max-width: $breakpoint-mobile) {
      & {
        width: 40vw;
        left: 30vw;
      }
    }
  }

  .hero1 {
    position: absolute;
    left: 10%;
    width: 80%;
    top: calc(
      (#{$logo-ratio}* 10vw) + 20px - 10vmin + 50vh - (#{$logo-ratio} * 5vw)
    );
    transform-origin: -180% 50%;
    animation: hero1-discard $sec $hero1-discard-dur ease-in forwards;

    span {
      display: block;
      line-height: 10vmin;
      font-size: 9vmin;
      font-weight: bold;
      color: $offwhite;
      margin: 5px 0 5px 0;
      @media (max-width: $breakpoint-tablet) {
        & {
          font-size: 7vmin;
          line-height: 8vmin;
        }
      }
    }

    .text-top {
      position: relative;
      overflow: hidden;
      height: calc(25vmin + 20px);
      @media (max-width: $breakpoint-tablet) {
        & {
          height: calc(23vmin + 20px);
        }
      }
      span {
        &:first-child {
          color: $color-red;
          line-height: 15vmin;
          font-size: 14vmin;
        }
      }
      & > div {
        position: absolute;
        top: 100%;
        animation: hero-up $sec $loading-dur-total forwards;
      }
    }
    .text-bottom {
      position: relative;
      overflow: hidden;
      height: calc(10vmin + 10px);
      @media (max-width: $breakpoint-tablet) {
        & {
          height: calc(8vmin + 10px);
        }
      }
      & > div {
        position: absolute;
        top: -100%;
        animation: hero-down $sec $loading-dur-total + $sec forwards;
        color: darken($color: $offwhite, $amount: 50);
      }
    }

    .bar {
      width: 0%;
      height: 5px;
      background: $bg;
      animation: progress-bar $loading-dur ease-in forwards;
    }
  }

  .hero2 {
    position: absolute;
    left: 10%;
    width: 80%;
    top: calc(
      (#{$logo-ratio}* 10vw) + 20px - 10vmin + 50vh - (#{$logo-ratio} * 5vw)
    );
    color: $offwhite;
    display: flex;
    flex-direction: column;

    span {
      display: block;
      line-height: 10vmin;
      font-size: 6vmin;
      font-weight: bold;
      text-align: center;
      i {
        font-style: normal;
        background: $color-red;
        padding: 5px 8px 5px 8px;
        color: $bg;
      }
      opacity: 0;
      animation: hero2-in $sec $hero1-discard-dur + 0.5s forwards;
    }
  }

  .arrows {
    position: absolute;
    bottom: 30px;
    left: calc(50% - 30px);
    cursor: pointer;
    width: 60px;
    height: 72px;
    opacity: 0;
    transform: scale(0);
    animation: hero2-in-arrows $sec $hero1-discard-dur + 1s forwards;
    -webkit-tap-highlight-color: transparent;

    @media (max-width: $breakpoint-mobile) {
      & {
        bottom: 90px;
      }
    }
  }

  .arrows path {
    stroke: $offwhite;
    fill: transparent;
    stroke-width: 1px;
    animation: hero2-arrows 2s infinite;

    &.a1 {
      animation-delay: -1s;
    }

    &.a2 {
      animation-delay: -0.5s;
    }

    &.a3 {
      animation-delay: 0s;
    }
  }
}

@keyframes turn-white {
  100% {
    background-color: $bg;
    background-image: url(../assets/bg.png);
  }
}

@keyframes turn-black {
  100% {
    filter: invert(99%);
  }
}

@keyframes go-to-corner {
  100% {
    top: 40px;
  }
}

@keyframes progress-bar {
  100% {
    background-color: $offwhite;
    width: 100%;
  }
}

@keyframes hero-up {
  40%,
  60% {
    top: 40%;
  }
  100% {
    top: 0;
  }
}

@keyframes hero-down {
  100% {
    top: 0;
  }
}

@keyframes hero1-discard {
  15% {
    opacity: 0;
  }
  100% {
    transform: rotate(-180deg);
    opacity: 0;
  }
}

@keyframes hero2-in {
  100% {
    opacity: 1;
  }
}

@keyframes hero2-in-arrows {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hero2-arrows {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
