.section3-container {
  width: 100%;
  max-width: 100%;
  color: $color-text-black;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5em;
  text-align: justify;
  position: relative;
  padding: 40px 80px;

  @media (max-width: $breakpoint-tablet) {
    padding: 40px;
    flex-direction: column;
  }

  ul {
    margin: 0;
    padding-left: 17px;
    li {
      margin-bottom: 0.8em;
    }
  }

  h1 {
    line-height: 1.2em;
  }

  img.section3-img {
    float: right;
    margin-left: 20px;
    shape-outside: circle(60% at 55% 54%);
    @media (max-width: $breakpoint-tablet) {
      width: 250px;
      height: 205px;
    }
  }
}
