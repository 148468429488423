@import "./style/variables.scss";

$sec: 0.8s;
$loading-dur: 0.5s;
$moving-dur: 0.6s;
$loading-dur-total: $loading-dur + $moving-dur;
$hero1-discard-dur: $loading-dur-total + 2.5 * $sec;
$logo-ratio: 0.253885481;
$bg: #212b34;
$offwhite: #f1f1f1;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //   overflow: hidden;
  //   &.body-unlock {
  //     overflow: auto;
  //   }
}

#root {
  margin: 0;
  padding: 0;
}

.screen {
  position: relative;
  width: 100vw;
  max-width: 100%;
}

@import "./style/hero.scss";
@import "./style/section1.scss";
@import "./style/section2.scss";
@import "./style/section3.scss";
@import "./style/section4.scss";
@import "./style/footer.scss";
