// Color palette
$color-dark: #212b34;
$color-red: #ff0f54;
$color-blue: #7ac7c4;
$color-teal: #c4edde;

// Colors
$color-text-black: #2c2b3c;
$color-text-white: #f1f1f1;

// Responsive
$breakpoint-tablet: 768px;
$breakpoint-mobile: 576px;
