$width: 20vw;
$radius: 100px;

.section2-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2em;
  position: relative;
  overflow: hidden;
  background-color: $bg;
  color: $offwhite;
  height: 100vh;
  padding: 40px 80px;

  .section2-text {
    z-index: 1;
    padding-left: calc(20vw - 80px);
    padding-right: 60px;

    h1 {
      margin-top: 40px;
      padding-left: 20px;
      line-height: 1.1em;

      @media (max-width: $breakpoint-mobile) {
        & {
          margin-top: 0px;
          font-size: 1.5em;
          line-height: 1.1em;
        }
      }
    }

    ul {
      margin-top: 20px;
      list-style: none;
      padding: 0;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      height: calc(100vh - #{$radius * 2.1});

      li {
        position: relative;
        margin: auto 0;

        &:first-child {
          margin-top: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          display: block;
          content: "";
          position: absolute;
          left: -38px;
          width: 16px;
          height: 16px;
          box-sizing: content-box;
          border: 5px solid $bg;
          border-radius: 16px;
          background: $offwhite;
        }

        h4 {
          margin: 0;
          padding: 0;
          font-size: 18px;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 16px;
        }
      }
    }
  }

  .dashed {
    width: $width;
    border-radius: 0 $radius $radius 0;
    height: 110vh;
    position: absolute;
    border: 10px dashed $color-red;
    border-left: none;
    top: -5vh;
    left: 0;
  }

  .dashed-back {
    width: $width;
    border-radius: $radius 0 0 $radius;
    height: 110vh;
    position: absolute;
    border: 10px dashed lighten($color: $color-red, $amount: 5);
    border-right: none;
    top: -5vh;
    left: $width/3;
    opacity: 0.2;
  }

  .solid {
    width: $width;
    height: calc(110vh - #{$radius * 2.1});
    position: absolute;
    border-right: 10px solid $color-red;
    top: calc(-5vh + #{$radius});
    left: 0;
  }
}
